import React from 'react'

const UniversityHeader = () => {
    return (
        <div>



            <div className='flex gap-6'>
                <div>
                    <button className="bg-black text-white text-[12px] px-4 py-2 rounded pt-sans-bold ">
                        Unassigned Universities
                    </button>

                </div>
                <div>
                    <button className="border border-1 border-black text-[12px]  text-black px-4 py-2 rounded pt-sans-bold ">
                       Associates
                    </button>

                </div>
            </div>






        </div>
    )
}

export default UniversityHeader