import React from 'react'
import Header from '../Header/Header'
import SideBar from '../SideBar/SideBar'
import CountryListingPage from './CountryListingPage'
import UniversityHeader from '../universityHeader/UniversityHeader'

const CountryDashboard = () => {
    return (
        // <div className='relative'>
        //     <div>
        //         <Header />
        //     </div>
        //     <hr />
        //     <div className='flex '>
        //         <div className='pt-2  shadow-sm'>
        //             <SideBar />
        //         </div>
        //         <div className='flex-grow'>

        //             <div className=' '>

        //             </div>
        //         </div>
        //     </div>
        // </div>




        <div>
            <div className='relative'>

                <hr />
                <div className='flex pt- shadow-lg'>
                    <div className='pt-10 bg-white h-full sticky top-0 '>
                        <SideBar />
                    </div>
                    <div className='flex-grow bg-gray-50'>
                        <div className=' py-6  px-4'>
                            <UniversityHeader />

                        </div>

                        <div className='flex justify-center '>
                            <CountryListingPage />
                        </div>
                    </div>
                </div>
            </div>





        </div>









    )
}

export default CountryDashboard