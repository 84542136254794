import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance/axiosInstance';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import CountryCourseDropdown from '../coursesDetails/CountryCourseDropdown';
import UniversityDropdown from '../coursesDetails/UniversityDropdown';
import EducationLevelDropdown from '../coursesDetails/EducationLevelDropdown';
import EducationDurationDropdown from '../coursesDetails/EducationDurationDropdown';
import EducationModeDropdown from '../coursesDetails/EducationModeDropdown';
import StudyGapDropdown from '../coursesDetails/StudyGapDropdown';
import POFDropdown from '../coursesDetails/POFDropdown';
import LanguageRequirement from '../coursesDetails/LanguageRequirement';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import { AnimatePresence } from 'framer-motion';
import DescriptionModal from './DescriptionModal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LanguageRequirementDrop from './LanguageRequirementDrop';
const UniversityCourse = ({ universityDetails, universityId }) => {
    const [courses, setCourses] = useState([]); // Store the courses data
    const [nextPage, setNextPage] = useState(null); // Initial URL for pagination
    const [prevPage, setPrevPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false); // Loading state for API request
    const [hasMore, setHasMore] = useState(true); // Determine if there are more items to fetch
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);



    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState('');
    const [Date, setDate] = useState(null);
    const [expiryDate, setExpiryDate] = useState(null);
    const [educationStatus, setEduactionStatus] = useState(null);
    const [educationOpen, setEducationOpen] = useState(null)
    const [educationLevels, setEducationLevels] = useState([]);
    const [educationModeOptions, setEducationModeOptions] = useState([]);
    const [countryDropOptions, setCountryDropOptions] = useState([]);
    const [universityDropOptions, setUniversityDropOptions] = useState([])

    const [countryStatus, setCountryStatus] = useState(null)
    const [countryOpen, setCountryOpen] = useState(null)
    const [duration, setDuration] = useState(null);
    const [durationOpen, setDurationOpen] = useState(null);
    const [educationModestatus, setEducationModeStatus] = useState(null)
    const [educationModeOpen, setEducationModeOpen] = useState(null)
    const [studyGapstatus, setStudyGapStatus] = useState(null)
    const [studyGapOpen, setStudyGapOpen] = useState(null)
    const [pofMethodStatus, setPofMethodStatus] = useState(null)
    const [pofMethodOpen, setPofMethodOpen] = useState(null)
    // const [languagestatus, setLanguageStatus] = useState(null)
    // const [languageOpen, setLanguageOpen] = useState(null)
    const [universityOpen, setUniversityOpen] = useState(null)
    const [universityStatus, setUniversityStatus] = useState(null)
    const [countryOptions, setCountryOptions] = useState([]);
    const [universityOptions, setUniversityOptions] = useState([]);
    const [newTagInput, setNewTagInput] = useState('');
    const [uniqueIdCounter, setUniqueIdCounter] = useState(1);
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [courseLevels, setCourseLevels] = useState([]); // Store course levels
    const [selectedTab, setSelectedTab] = useState(''); // Track the selected tab
    const [languageOptions, setLanguageOptions] = useState([]);
    const [languagestatus, setLanguagestatus] = useState("");
    const [languageOpen, setLanguageOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedCourseId, setSelectedCourseId] = useState(null);



    const [formData, setFormData] = useState({

        name: "",
        campus: "",
        course_duration: "",
        education_level: null,
        fee_per_year: "",
        POF: "",
        POF_mode: "",


        intake: "",
        discription: "",
        expiry_date: "",
        education_duration: "",
        education_mode: null,
        minimum_mark: "",
        study_gap: "",
        tags: [],
        language_requirements: []


    });
    const [errors, setErrors] = useState({});
    const validate = () => {
        let newErrors = {};
        if (!formData.campus) {
            newErrors.campus = "Campus is required.";
        }
        if (!formData.name) {
            newErrors.name = "Course name is required.";
        }
        if (!formData.course_duration) {
            newErrors.course_duration = "Course duration is required.";
        }
        // if (!formData.education_level) {
        //     newErrors.education_level = "Education level is required.";
        // }
        if (!formData.fee_per_year) {
            newErrors.fee_per_year = "Fees Per Year is required.";
        } else if (isNaN(formData.fee_per_year)) {
            newErrors.fee_per_year = "Fees Per Year must be a number.";
        }
        if (!formData.POF) {
            newErrors.POF = "POF is required.";
        } else if (isNaN(formData.POF)) {
            newErrors.POF = "POF must be a number.";
        }
        if (!formData.POF_mode) {
            newErrors.POF_mode = " POF Mode is required.";
        }
        if (!formData.language_requirements) {
            newErrors.llanguage_requirements = "Language is required.";
        }

        if (!formData.intake) {
            newErrors.intake = " Intake is required.";
        }
        // if (!formData.discription) {
        //     newErrors.discription = "Discription is required.";
        // }
        if (!formData.expiry_date) {
            newErrors.expiry_date = "Expiry date is required.";
        }
      
        if (!formData.education_duration) {
            newErrors.education_duration = " Education duration is required.";
        }
        if (!formData.education_mode) {
            newErrors.education_mode = "Education mode is required.";
        }
        if (!formData.minimum_mark) {
            newErrors.minimum_mark = "Minimum mark is required.";
        } else if (isNaN(formData.minimum_mark)) {
            newErrors.minimum_mark = "Minimum mark must be a number.";
        }
        if (!formData.study_gap) {
            newErrors.study_gap = "Study_gap is required.";
        }
        // Add more validations as needed for other fields
        return newErrors;
    };
    useEffect(() => {
        axiosInstance.get('home/api/v1/language-requirements/')
            .then(response => {
                setLanguageOptions(response.data); // Assuming API returns an array of options
            })
            .catch(error => {
                console.error('Error fetching language options:', error);
            });
    }, []);


    useEffect(() => {
        const fetchEducationLevels = async () => {
            try {
                const response = await axiosInstance.get('home/api/v1/education_level_dropdown/');
                if (response.status === 200) {
                    setEducationLevels(response.data); // Use the data as is
                } else {
                    console.error('Failed to fetch education levels');
                }
            } catch (error) {
                console.error('Error fetching education levels:', error);
            }
        };

        fetchEducationLevels();
    }, []);
    useEffect(() => {
        const fetchEducationMode = async () => {
            try {
                const response = await axiosInstance.get('home/api/v1/education_mode_dropdown/');
                if (response.status === 200) {
                    setEducationModeOptions(response.data); // Use the data as is
                } else {
                    console.error('Failed to fetch education levels');
                }
            } catch (error) {
                console.error('Error fetching education levels:', error);
            }
        };

        fetchEducationMode();
    }, []);
    useEffect(() => {
        const fetchCountry = async () => {
            try {
                const response = await axiosInstance.get('kanban/api/v1/country_dropdown/');
                if (response.status === 200) {
                    setCountryDropOptions(response.data); // Use the data as is
                } else {
                    console.error('Failed to fetch education levels');
                }
            } catch (error) {
                console.error('Error fetching education levels:', error);
            }
        };

        fetchCountry();
    }, []);

    useEffect(() => {
        const fetchUniversitiesByCountry = async () => {
            if (formData.country) { // Ensure country ID is set
                try {
                    const response = await axiosInstance.get(`home/api/v1/university-dropdown/?country=${formData.country}`);
                    if (response.status === 200) {
                        setUniversityDropOptions(response.data);
                    } else {
                        console.error('Failed to fetch universities');
                    }
                } catch (error) {
                    console.error('Error fetching universities:', error);
                }
            }
        };

        fetchUniversitiesByCountry();
    }, [formData.country]);
    const handleChange = (field) => (option) => {
        setFormData((prev) => ({ ...prev, [field]: option }));
    };

    const handleEducationlevel = (option) => {
        setEduactionStatus(option.name);
        setFormData({ ...formData, education_level: option.id });
    };
    const handleOpenDescriptionModal = () => {
        setIsDescriptionModalOpen(true);
    };

    const handleCloseDescriptionModal = () => {
        setIsDescriptionModalOpen(false);
    };
    const handleSaveDescription = (description) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            description, // Update the description in formData
        }));
    };











    const handleCountry = (option) => {
        setCountryStatus(option.name); // This updates the UI with the selected country name
        setFormData({ ...formData, country: option.id }); // Ensure only the country ID is sent to the API
    };

    const handleUniversity = (option) => {
        setUniversityStatus(option.name); // This updates the UI with the selected university name
        setFormData({ ...formData, university: option.id }); // Ensure only the university ID is sent to the API
    };

    const handleEducationDuration = (option) => {
        setDuration(option);
        setFormData({ ...formData, education_duration: option });
    };
    const handleEducationMode = (option) => {
        const selectedValue = option.name; // Extracting selected value from option

        setEducationModeStatus(selectedValue);
        setFormData({ ...formData, education_mode: option.id });

        // Remove error if a valid selection is made
        if (selectedValue && selectedValue.trim() !== "") {
            setErrors(prevErrors => {
                const { education_mode, ...rest } = prevErrors; // Destructure to remove the error
                return rest; // Return the updated errors without education_mode error
            });
        } else {
            // Optionally set an error if selection is invalid
            setErrors(prevErrors => ({
                ...prevErrors,
                education_mode: "Please select a valid education mode.", // Set the error message
            }));
        }
    };
    const handleLanguage = (selectedRequirements) => {
        // Clear previous error
        setErrors((prevErrors) => ({
            ...prevErrors,
            language_requirement: null,
        }));

        // Check if at least one language requirement is selected
        if (selectedRequirements.length === 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                language_requirement: "Please select at least one language requirement.",
            }));
            return;
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            language_requirements: selectedRequirements, // Update language requirements
        }));
    };






    const handlePofMethod = (option) => {
        setPofMethodStatus(option);
        setFormData({ ...formData, POF_mode: option });
    };
    // const handleLanguage = (option) => {
    //     setLanguageStatus(option);
    //     setFormData({ ...formData, language: option });
    // };
    const handleStudyGap = (option) => {
        setStudyGapStatus(option);
        setFormData({ ...formData, study_gap: option });
    };
    const toggleEducationlevelDropdown = () => {
        setEducationOpen((prevState) => !prevState);
    };
    const toggleEducationDuration = () => {
        setDurationOpen((prevState) => !prevState);
    };
    const toggleCountryDropdown = () => {
        setCountryOpen(prev => !prev);
    };

    const toggleUniversityDropdown = () => {
        setUniversityOpen(prev => !prev);
    };
    const toggleEducationMode = () => {
        setEducationModeOpen((prevState) => !prevState);
    };
    const togglePofMethod = () => {
        setPofMethodOpen((prevState) => !prevState);
    };
    const toggleLanguage = () => {
        setLanguageOpen((prevState) => !prevState);
    };
    const toggleStudyGap = () => {
        setStudyGapOpen((prevState) => !prevState);
    };
    const handleDateChange = (date) => {
        if (date) { // Check if date is valid
            const formattedDate = format(date, 'yyyy-MM-dd'); // Format the date only if valid
            setDate(date);
            setFormData({ ...formData, intake: formattedDate });
        } else {
            setDate(null);
            setFormData({ ...formData, intake: "" });
        }
    };
    const handleExpiryDateChange = (date) => {
        if (date) { // Check if date is valid
            const formattedExpiryDate = format(date, 'yyyy-MM-dd');
            setExpiryDate(date);
            setFormData({ ...formData, expiry_date: formattedExpiryDate });
        } else {
            setExpiryDate(null);
            setFormData({ ...formData, expiry_date: "" });
        }
    };
    const handleTagChange = (e) => {
        setNewTagInput(e.target.value);
    };


    const handleSubmit = async () => {
        const newErrors = validate();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }




        const newTagInputStr = typeof newTagInput === 'string' ? newTagInput : '';

        // Split tags if newTagInput is a string
        const inputTags = newTagInputStr.split(',').map(tag => tag.trim()).filter(tag => tag);



        // Extract tags from existing form data
        const existingTags = formData.tags.map(tagObj => {
            try {
                const parsedTag = JSON.parse(tagObj.tag);
                return parsedTag.tag;
            } catch (error) {
                console.error('Error parsing tag:', error);
                return tagObj.tag; // Fallback to raw tag if parsing fails
            }
        });
        let updatedTags = existingTags;
        if (inputTags.length > 0) {
            updatedTags = [...new Set([...existingTags, ...inputTags])];
        }
        setTags(updatedTags);
        setFormData(prevFormData => ({
            ...prevFormData,
            tags: updatedTags,
            education_level: selectedTab
        }));
        // Clear input field


        // Convert education_mode and education_level to IDs if they are strings and not already IDs
        const convertToId = (value, options) => {
            if (typeof value === 'string') {
                const foundOption = options.find(option => option.name === value);
                return foundOption ? foundOption.id : value;
            }
            return value;
        };

        const educationModeId = convertToId(formData.education_mode, educationModeOptions);

        const educationLevelId = convertToId(selectedTab, educationLevels);

        console.log(selectedTab); // Make sure this logs the correct tab before submission




        // Prepare language requirements from form data
        const languageRequirements = formData.language_requirements.map(req => {
            const foundOption = languageOptions.find(option => option.name === req.language_requirement);
            return {
                language_requirement: foundOption ? foundOption.id : req.language_requirement, // Use ID instead of name
                score: req.score
            };
        });



        // Submit form data when Enter is pressed
        try {
            const response = await axiosInstance.post('home/api/v1/courses/', {
                ...formData,
                tags: updatedTags,

                education_mode: educationModeId, // Ensure this is an ID
                education_level: educationLevelId, // Ensure this is an ID
                language_requirements: languageRequirements,
                university: universityId
            });

            if (response.status === 201) {
                console.log('Course added successfully.');
                fetchCourse(0);
                setShowDeleteButton(false)
                setEduactionStatus('')
                setDuration('')
                setStudyGapStatus('')
                setEducationModeStatus('')
                setPofMethodStatus('')
                setLanguagestatus('')
                setNewTagInput([]);
                setTags([])
                setDate('')
                setExpiryDate('')
                setFormData({
                    name: "",
                    campus: "",
                    course_duration: "",
                    education_level: null,
                    fee_per_year: "",
                    POF: "",
                    POF_mode: "",

                    score: "",
                    intake: "",
                    discription: "",
                    expiry_date: "",
                    education_duration: "",
                    education_mode: null,
                    minimum_mark: "",
                    study_gap: "",
                    tags: [],
                    language_requirements: []
                });

                // Optionally reset formData or perform other actions
                setFormData(prev => ({
                    ...prev,
                    tags: []
                }));
            } else {
                console.log('Course added successfully:', response.data);
            }
        } catch (error) {
            console.error('Error adding course:', error);
        }


    };



    // Handle tag deletion
    const handleTagDelete = (tagToDelete) => {
        setTags(prevTags => prevTags.filter(tag => tag !== tagToDelete));
        setFormData(prevFormData => ({
            ...prevFormData,
            tags: prevFormData.tags.filter(tag => tag !== tagToDelete)
        }));
    };







    const fetchCourse = (educationTypeId, url = `home/api/v1/courses/?university=${universityId}&educationtype=${educationTypeId}&limit=4&offset=0`) => {
        axiosInstance.get(url)
            .then(response => {
                setCourses(response.data.results);
                setNextPage(response.data.next);
                setPrevPage(response.data.previous);
            })
            .catch(error => {
                console.error("There was an error fetching the university data!", error);
            });
    };

    useEffect(() => {
        fetchCourse(); // Initial fetch on component mount
    }, []);
    const handleNext = () => {
        if (nextPage) {
            fetchCourse("", nextPage);
            setCurrentPage((prev) => prev + 1);
        }
    };

    // Handle "Previous" button click
    const handlePrevious = () => {
        if (prevPage) {
            fetchCourse("", prevPage);
            setCurrentPage((prev) => prev - 1);
        }
    };




    const fetchCourseDetails = async (courseId) => {

        setLoading(true);
        try {
            const response = await axiosInstance.get(`home/api/v1/course_detail/${courseId}/`);
            const data = response.data;

            setEduactionStatus(data.education_level)
            setDuration(data.education_duration)
            setStudyGapStatus(data.study_gap)
            setEducationModeStatus(data.education_mode)
            setPofMethodStatus(data.POF_mode)
            const languageRequirements = data.language_requirements.map(req => ({
                language_requirement: req.language_requirement,
                score: req.score
            }));

            setLanguagestatus(languageRequirements.map(req => `${req.language_requirement}: ${req.score}`).join(', '));

            setTags(data.tags || []);
            setDate(data.intake)
            setExpiryDate(data.expiry_date)

            setFormData({
                ...data, // Assuming the API returns data in the same format as formData
                tags: data.tags || [],
                language_requirements: languageRequirements// Ensure tags are handled properly
            });
        } catch (error) {
            console.error('Error fetching course details:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleDuplicate = (courseId) => {
        fetchCourseDetails(courseId);
        setShowDeleteButton(true);
    };
    // Function to handle modal opening
    const openModal = (courseId) => {
        setSelectedCourseId(courseId);
        setShowModal(true);
    };

    // Function to close modal
    const closeModal = () => {
        setShowModal(false);
        setSelectedCourseId(null);
    };

    // Function to confirm deactivation
    const confirmDeactivation = () => {
        handleDeactivateCourse(selectedCourseId);
        closeModal();
    };

    // const handleDuplicate = async (courseId) => {
    //     setLoading(true);
    //     setShowDeleteButton(true);
    //     try {
    //         const response = await axiosInstance.get(`home/api/v1/course_detail/${courseId}/`);
    //         const data = response.data;

    //         setEduactionStatus(data.education_level);
    //         setDuration(data.education_duration);
    //         setStudyGapStatus(data.study_gap);
    //         setEducationModeStatus(data.education_mode);
    //         setPofMethodStatus(data.POF_mode);

    //         // Ensure language requirements are properly set
    //         const languageRequirements = data.language_requirement.map(req => ({
    //             language_requirement: req.language_requirement,
    //             score: req.score
    //         }));

    //         setLanguagestatus(languageRequirements.map(req => `${req.language_requirement}: ${req.score}`).join(', '));
    //         setFormData(prevFormData => ({
    //             ...prevFormData,
    //             language_requirements: languageRequirements, // Update language requirements in formData
    //             // Add other fields as needed
    //         }));

    //         setTags(data.tags || []);
    //         setDate(data.intake);
    //         setExpiryDate(data.expiry_date);
    //     } catch (error) {
    //         console.error('Error fetching course details:', error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };





    const handleDelete = () => {
        setFormData({
            campus: "",
            name: "",
            course_duration: "",
            education_level: null,
            fee_per_year: "",
            POF: "",
            POF_mode: "",
            language_requirements: [],
            score: "",
            intake: "",
            discription: "",
            expiry_date: "",
            education_duration: "",
            education_mode: null,
            minimum_mark: "",
            study_gap: "",
            tags: []
        });
        setEduactionStatus('')
        setDuration('')
        setStudyGapStatus('')
        setEducationModeStatus('')
        setPofMethodStatus('')
        setLanguagestatus([])
        setTags([]);
        setDate('')
        setExpiryDate('')


        setShowDeleteButton(false);
    };
    const handleTabClick = (tabName) => {
        setSelectedTab(tabName.name);

        // Fetch courses based on the selected tab
        fetchCourse(tabName.id); // Assuming the API uses lowercase names like 'masters', 'bachelors', etc.
        setCurrentPage(1); // Reset to the first page
    };
    useEffect(() => {
        const fetchCourseLevels = async () => {
            try {
                const response = await axiosInstance.get('home/api/v1/course-levels/');
                setCourseLevels(response.data); // Store course levels from API
                if (response.data.length > 0) {
                    setSelectedTab(response.data[0].name);
                    fetchCourse(response.data[0].id);  // Set default tab to the first one
                }
            } catch (error) {
                console.error("Error fetching course levels:", error);
            }
        };

        fetchCourseLevels();
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === 'Return') {
            e.preventDefault();
            handleSubmit();
        }
    };

    // Handle Save button click
    const handleSave = () => {
        console.log('Save button clicked');
        handleSubmit();
    };
    const handleDeactivateCourse = async (courseId) => {
        try {
            const response = await axiosInstance.patch(`home/api/v1/course_detail/${courseId}/`, {
                is_active: false  // Set is_active to false instead of deleting
            });
            console.log('Course deactivated successfully:', response.data);

            // After successful deactivation, update the state
            setCourses((prevCourses) =>
                prevCourses.map(course =>
                    course.id === courseId ? { ...course, is_active: false } : course
                )
            );

            fetchCourse();  // Optionally refetch the course list to refresh the data
        } catch (error) {
            console.error('Error deactivating course:', error);
        }
    };









    return (
        <div className=''>
            <div className='px-6 py-4 pt-6'>
                <div className="bg-white shadow-md"
                >
                    <div className="flex flex-col justify-between gap-4 px-4 py-4">
                        <div>
                            <div className="flex  justify-between item-center">
                                <div className='flex gap-4'>
                                    <img src={universityDetails.
                                        logo_image} alt="University Logo" className="w-12 h-12 rounded-full" />
                                    <div>
                                        <div className='flex items-center gap-2'>
                                            <div className='text-[13px] pt-sans-bold '>{universityDetails.name}</div>
                                            {universityDetails.is_norvel_recommended && (
                                                <div className="pt-sans-bold text-[10px] bg-green-500 rounded-sm px-1 whitespace-nowrap">
                                                    Norvel Recommended
                                                </div>
                                            )}
                                        </div>

                                        <div className='flex gap-2'>
                                            <div className='text-[12px]'>Fees Starts at : <span className='text-[10px] pt-sans-bold '>{universityDetails.minimum_fee_per_year} Lakhs per years*  </span>
                                                {universityDetails.language_requirement && (
                                                    <span className='text-[11px] pt-sans-bold'>
                                                        {universityDetails.language_requirement.name}
                                                    </span>
                                                )}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='hidden md:block'>
                                    <div className="flex gap-1  items-center">
                                        <div className='pt-sans-bold text-[12px]'>{universityDetails.country?.name}</div>
                                        <img src={universityDetails.country.flag


                                        } alt="University Logo" className="w-8 h-8 rounded-full" />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class=" border border-dotted  border-gray-300 flex items-center justify-center "></div>

                        <div className='flex justify-between'>
                            <div className='hidden md:block' >
                                <div className='text-[12px]'>Location:</div>
                                <div className='text-[12px] pt-sans-bold  '>{universityDetails.location}</div>

                            </div>
                            <div class="border-dotted-b   flex items-center justify-center block md:hidden"></div>

                            <div className="flex">

                                <div className='px-6 flex gap-2 items-center'>
                                    <div>
                                        <img src="./scholarships.png" className='w-7 h-7 object-contain' />

                                    </div>
                                    <div>
                                        <div className='text-[13px] pt-sans-regular'>Diploma</div>
                                        <div className='pt-sans-bold text-[12px]'>{universityDetails.education_levels_count?.Diploma}</div>
                                    </div>
                                </div>
                                <div className='px-6 flex gap-2 items-center'>
                                    <div>
                                        <img src="./bachelors.png" className='w-6 h-6 object-contain' />

                                    </div>
                                    <div>
                                        <div className='text-[13px] pt-sans-regular'>Bachelor's</div>
                                        <div className='pt-sans-bold text-[12px]'>{universityDetails.education_levels_count?.Bachelors}</div>
                                    </div>
                                </div>
                                <div className='px-6 flex gap-2 items-center'>
                                    <div>
                                        <img src="./masters.png" className='w-6 h-6 object-contain' />

                                    </div>
                                    <div>
                                        <div className='text-[13px] pt-sans-regular'>Masters</div>
                                        <div className='pt-sans-bold text-[12px]'>{universityDetails.education_levels_count?.Masters}</div>
                                    </div>
                                </div>
                                <div className='px-6 flex gap-2 items-center'>
                                    <div>
                                        <img src="./type.png" className='w-6 h-6 object-contain' />

                                    </div>
                                    <div>
                                        <div className='text-[13px] pt-sans-regular'>Type</div>
                                        <div className='pt-sans-bold text-[12px]'>{universityDetails.university_type}</div>
                                    </div>
                                </div>
                                <div className='px-6 flex gap-2 items-center'>
                                    <div>
                                        <img src="./rank.png" className='w-6 h-6 object-contain' />

                                    </div>
                                    <div>
                                        <div className='text-[13px] pt-sans-regular'>Global Ranking</div>
                                        <div className='pt-sans-bold text-[12px]'>{universityDetails.rank}</div>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </div>
                </div>
            </div>

            <div className="flex  px-8 pb-6 ">
                {courseLevels.map((level) => (
                    <button
                        key={level.id}
                        className={`px-4 py-2 text-[12px] font-bold ${selectedTab === level.name ? 'text-black border-b-2 border-green-600' : 'text-gray-500 border-b-0'
                            }`}
                        onClick={() => handleTabClick(level)} // Pass the whole level object
                    >
                        {level.name}
                    </button>
                ))}
            </div>
            <div className="  overflow-x-auto px-6 h-[50vh] ">
                <table className=" table-auto">
                    <thead>
                        <tr className="bg-gray-50 rounded-md text-[12px] whitespace-nowrap text-gray-500 text-left">
                            {/* Table headers */}
                            <th className="px-4 py-2  ">Course <span className='text-red-600 '>*</span></th>
                            <th className="px-4 py-2  ">Campus <span className='text-red-600 '>*</span></th>

                            {/* <th className="px-4 py-2  ">EducationLevel</th> */}
                            <th className="px-4 py-2  ">Course Duration <span className='text-red-600 '>*</span></th>
                            <th className="px-4 py-2  ">Education Duration <span className='text-red-600 '>*</span></th>
                            <th className="px-4 py-2  ">Education Mode <span className='text-red-600 '>*</span></th>
                            <th className="px-4 py-2  ">Minimum Mark In Percentage <span className='text-red-600 '>*</span></th>
                            <th className="px-4 py-2  ">Study Gap <span className='text-red-600 '>*</span></th>
                            <th className="px-4 py-2  ">Fees / Year (INR) <span className='text-red-600 '>*</span></th>
                            <th className="px-4 py-2  ">Proof of Fund (INR) <span className='text-red-600 '>*</span></th>
                            <th className="px-4 py-2  ">POF Method <span className='text-red-600 '>*</span></th>
                            <th className="px-4 py-2  ">Language Requirement <span className='text-red-600 '>*</span></th>

                            <th className="px-4 py-2  ">Intake <span className='text-red-600 '>*</span></th>

                            {/* <th className="px-4 py-2  ">Description</th> */}
                            <th className="px-4 py-2  ">Expiry Date <span className='text-red-600 '>*</span></th>
                            <th className="px-4 py-2  ">Tags</th>


                        </tr>
                    </thead>
                    <tbody>

                        <tr>


                            <td>
                                <input
                                    id="input-field"
                                    type="text"
                                    className="border border-gray-300  w-full md:w-[200px]   text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                    placeholder="Enter Course"
                                    value={formData.name}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setFormData({ ...formData, name: value })
                                        // Remove error when the user enters valid data
                                        if (value.trim() !== "") {
                                            setErrors(prevErrors => {
                                                const { name, ...rest } = prevErrors;
                                                return rest;
                                            });
                                        }


                                    }}
                                />
                                {errors.name && <p className='text-red-500 text-center text-[10px]'>{errors.name}</p>}
                            </td>
                            <td className="py-2">
                                {/* <input
                                    id="input-field"
                                    type="text"
                                    value={formData.campus}
                                    className="border border-gray-300  w-full md:w-[200px]   text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                    placeholder="Enter Campus"
                                    onChange={(e) => setFormData({ ...formData, campus: e.target.value })
                                    
                                }
                                /> */}
                                <input
                                    id="input-field"
                                    type="text"
                                    value={formData.campus}
                                    className="border border-gray-300  w-full md:w-[200px]   text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                    placeholder="Enter Campus"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setFormData({ ...formData, campus: value });

                                        // Remove error when the user enters valid data
                                        if (value.trim() !== "") {
                                            setErrors(prevErrors => {
                                                const { campus, ...rest } = prevErrors;
                                                return rest;
                                            });
                                        }
                                    }}
                                />
                                {errors.campus && <p className='text-red-500 text-center text-[10px]'>{errors.campus}</p>}
                            </td>

                            <td>
                                <input
                                    id="input-field"
                                    type="text"
                                    value={formData.course_duration}
                                    className="border border-gray-300  w-full md:w-[200px]   text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                    placeholder="Enter course Duration"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setFormData({ ...formData, course_duration: value })
                                        if (value.trim() !== "") {
                                            setErrors(prevErrors => {
                                                const { course_duration, ...rest } = prevErrors;
                                                return rest;
                                            });
                                        }
                                    }


                                    }
                                />
                                {errors.course_duration && <p className='text-red-500 text-center text-[10px]'>{errors.course_duration}</p>}

                            </td>
                            <td>
                                <EducationDurationDropdown
                                    options={['16 years Education(UG+PG)/4 UG)', '15 years Education ( UG/3 Diploma)', '15 years (UG only)', '+2']}
                                    selectedOption={duration}
                                    onSelect={(selectedValue) => {
                                        handleEducationDuration(selectedValue);

                                        // Remove error if a valid selection is made
                                        if (selectedValue.trim() !== "") {
                                            setErrors(prevErrors => {
                                                const { education_duration, ...rest } = prevErrors;
                                                return rest;
                                            });
                                        }
                                    }}
                                    toggleDropdown={toggleEducationDuration}
                                    durationOpen={durationOpen}
                                    setDurationOpen={setDurationOpen}
                                />
                                {errors.education_duration && <p className='text-red-500 text-center text-[10px]'>{errors.education_duration}</p>}
                            </td>
                            <td>
                                <EducationModeDropdown
                                    options={educationModeOptions}
                                    selectedOption={educationModestatus}
                                    onSelect={handleEducationMode}
                                    toggleDropdown={toggleEducationMode}
                                    educationModeOpen={educationModeOpen}
                                    setEducationModeOpen={setEducationModeOpen}
                                />
                                {errors.education_mode && <p className='text-red-500 text-center text-[10px]'>{errors.education_mode}</p>}
                            </td>
                            <td>
                                <input
                                    id="input-field"
                                    type="text
                                    "
                                    value={formData.minimum_mark}
                                    className="border border-gray-300  w-full md:w-[200px]  text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                    placeholder="Enter Minimum Mark"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setFormData({ ...formData, minimum_mark: value })
                                        if (value.trim() !== "") {
                                            setErrors(prevErrors => {
                                                const { minimum_mark, ...rest } = prevErrors;
                                                return rest;
                                            });
                                        }
                                    }


                                    }
                                />
                                {errors.minimum_mark && <p className='text-red-500 text-center text-[10px]'>{errors.minimum_mark}</p>}




                            </td>
                            <td>
                                <StudyGapDropdown
                                    options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+']}
                                    selectedOption={studyGapstatus}
                                    onSelect={(selectedValue) => {
                                        handleStudyGap(selectedValue);

                                        // Remove error if a valid selection is made
                                        if (selectedValue.trim() !== "") {
                                            setErrors(prevErrors => {
                                                const { study_gap, ...rest } = prevErrors;
                                                return rest;
                                            });
                                        }
                                    }}
                                    toggleDropdown={toggleStudyGap}
                                    studyGapOpen={studyGapOpen}
                                    setStudyGapOpen={setStudyGapOpen}
                                />
                                {errors.study_gap && <p className='text-red-500 text-center text-[10px]'>{errors.study_gap}</p>}

                            </td>
                            <td>
                                <input
                                    id="input-field"
                                    type="text"
                                    value={formData.fee_per_year}
                                    className="border border-gray-300  w-full md:w-[200px]   text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                    placeholder="Enter fees"
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        setFormData({ ...formData, fee_per_year: value })
                                        if (value.trim() !== "") {
                                            setErrors(prevErrors => {
                                                const { fee_per_year, ...rest } = prevErrors;
                                                return rest;
                                            });
                                        }
                                    }


                                    }
                                />
                                {errors.fee_per_year && <p className='text-red-500 text-center text-[10px]'>{errors.fee_per_year}</p>}
                            </td>
                            <td>
                                <input
                                    id="input-field"
                                    type="text"
                                    value={formData.POF}
                                    className="border border-gray-300  w-full md:w-[200px]   text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                    placeholder="Enter POF"
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        setFormData({ ...formData, POF: value })
                                        if (value.trim() !== "") {
                                            setErrors(prevErrors => {
                                                const { POF, ...rest } = prevErrors;
                                                return rest;
                                            });
                                        }
                                    }


                                    }
                                />
                                {errors.POF && <p className='text-red-500 text-center text-[10px]'>{errors.POF}</p>}

                            </td>
                            <td>
                                <POFDropdown
                                    options={['Show Money', 'Credit Card Limit', 'Blocked Account']}
                                    selectedOption={pofMethodStatus}
                                    onSelect={(selectedValue) => {
                                        handlePofMethod(selectedValue);

                                        // Remove error if a valid selection is made
                                        if (selectedValue.trim() !== "") {
                                            setErrors(prevErrors => {
                                                const { POF_mode, ...rest } = prevErrors;
                                                return rest;
                                            });
                                        }
                                    }}
                                    toggleDropdown={togglePofMethod}
                                    pofMethodOpen={pofMethodOpen}
                                    setPofMethodOpen={setPofMethodOpen}

                                />
                                {errors.POF_mode && <p className='text-red-500 text-center text-[10px]'>{errors.POF_mode}</p>}

                            </td>



                            {/* <td>
                                <LanguageRequirementDrop
                                    options={languageOptions} // Use options from the API
                                    
                                    onSelect={handleLanguage}
                                    toggleDropdown={() => setLanguageOpen(!languageOpen)}
                                    languageOpen={languageOpen}
                                    setLanguageOpen={setLanguageOpen}
                                /> */}
                            <td>
                                <LanguageRequirementDrop
                                    options={languageOptions} // Use options from the API
                                    initialData={formData.language_requirements} // Pass language requirements as initial data
                                    onSelect={handleLanguage}
                                    selectedOption={languagestatus}
                                    toggleDropdown={() => setLanguageOpen(!languageOpen)}
                                    languageOpen={languageOpen}
                                    setLanguageOpen={setLanguageOpen}
                                    setErrors={setErrors} // Make sure you pass setErrors
                                />
                            </td>








                            <td>
                                <DatePicker


                                    selected={Date}
                                    onChange={(date) => {
                                        handleDateChange(date);

                                        // Remove error if a valid date is selected
                                        if (date) {
                                            setErrors(prevErrors => {
                                                const { intake, ...rest } = prevErrors;
                                                return rest;
                                            });
                                        }
                                    }}
                                    dateFormat="yyyy/MM/dd"
                                    placeholderText="Select Intake Date"
                                    className="border border-gray-300  w-full md:w-[200px]  text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                />
                                {errors.intake && <p className='text-red-500 text-center  text-[10px]'>{errors.intake}</p>}
                            </td>



                            <td>
                                <DatePicker

                                    selected={expiryDate}
                                    onChange={(date) => {
                                        handleExpiryDateChange(date);

                                        // Remove error if a valid date is selected
                                        if (date) {
                                            setErrors(prevErrors => {
                                                const { expiry_date, ...rest } = prevErrors;
                                                return rest;
                                            });
                                        }
                                    }}
                                    placeholderText="Select a date"
                                    dateFormat="MM/dd/yyyy"
                                    className="border border-gray-300 w-full md:w-[200px]  text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                />
                                {errors.expiry_date && <p className='text-red-500 text-center text-[10px]'>{errors.expiry_date}</p>}

                            </td>
                            <td>
                                <div className='flex '>
                                    <div>
                                        <input
                                            id="tags"
                                            type="text"
                                            value={newTagInput}
                                            onChange={handleTagChange}
                                            onKeyDown={handleKeyDown}
                                            placeholder="Add tags separated by commas"
                                            className="border border-gray-300 py-1.5 px-2 text-[12px] outline-none w-[200px]"
                                        />

                                    </div>



                                    <div className='flex px-4'>
                                        {tags.map((tag, id) => (
                                            <div
                                                key={id} // Use the tag string as the key
                                                className="border border-gray-300 rounded-full text-white bg-black inter-semilight  px-2 m-1 py-1 text-[12px]  flex items-center justify-between"
                                            >
                                                <span className='whitespace-nowrap '>{tag.tag}</span>
                                                <button
                                                    type="button"
                                                    onClick={() => handleTagDelete(tag)} // Use the tag string directly
                                                    className="ml-2 text-red-500"
                                                >
                                                    ×
                                                </button>
                                            </div>
                                        ))}
                                    </div>

                                </div>

                            </td>
                            <td>
                                <div className='flex'>
                                    <div>
                                        {showDeleteButton && (
                                            <button
                                                onClick={handleDelete}
                                                className="ml-2 hover:underline text-red-500"
                                            >
                                                <DeleteIcon style={{ fontSize: '17px' }} />
                                            </button>
                                        )}

                                    </div>

                                    <div className="flex gap-2 items-center justify-end px-2">

                                        <button
                                            className="px-4 py-1 whitespace-nowrap bg-black text-white text-[12px] inter-semilight font-bold rounded"
                                            onClick={handleOpenDescriptionModal}
                                        >
                                            Add Description
                                        </button>
                                        <button
                                            className="px-4 py-1 bg-black text-white text-[12px] inter-semilight  font-bold rounded"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </button>
                                    </div>

                                </div>

                            </td>

                        </tr>


                        {courses.map((course, index) => (
                            <tr key={index} className="bg-white   border border-1 rounded-md border-gray-200 shadow-lg  inter-semilight text-[12px]">
                                <td className=" px-4 py-2">{course.name || 'N/A'}</td>
                                <td className=" px-4 py-2">{course.campus || 'N/A'}</td>


                                <td className=" px-4 py-2">{course.course_duration || 'N/A'}</td>
                                <td className=" px-4 py-2">{course.education_duration || 'N/A'}</td>
                                <td className=" px-4 py-2 w-full overflow-x-auto">{course.education_mode || 'N/A'}</td>
                                <td className=" px-4 py-2">{course.minimum_mark || 'N/A'}</td>
                                <td className=" px-4 py-2">{course.study_gap || 'N/A'}</td>
                                <td className=" px-4 py-2">{course.fee_per_year || 'N/A'}</td>
                                <td className=" px-4 py-2">{course.POF || 'N/A'}</td>
                                <td className=" px-4 py-2">{course.POF_mode || 'N/A'}</td>
                                <td className="px-4 py-2">
                                    {course.language_requirements && Array.isArray(course.language_requirements) && course.language_requirements.length > 0 ? (
                                        <div className="flex flex-wrap">
                                            {course.language_requirements.map((requirement, index) => (
                                                <div key={index} className="flex items-center ">
                                                    <div>
                                                        {requirement.language_requirement}
                                                        {/* Add '/' for all but the last item */}
                                                        {index !== course.language_requirements.length - 1 && ' / '}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        'N/A'
                                    )}
                                </td>




                                <td className=" px-4 py-2">{course.intake || 'N/A'}</td>

                                {/* <td className=" px-4 py-2">{course.description || 'N/A'}</td> */}
                                <td className=" px-4 py-2">{course.expiry_date || 'N/A'}</td>
                                <td className="px-4 flex justify-center items-center py-2">
                                    {course.tags.length > 0 ? (
                                        course.tags.map((tag, index) => (
                                            <div key={index} className='flex '>
                                                <span

                                                    className="inline-block bg-black text-white px-4 py-0.5 rounded-full whitespace-nowrap  text-[12px] mr-1 mb-1"
                                                >
                                                    {tag.tag}
                                                </span>

                                            </div>

                                        ))
                                    ) : (
                                        'N/A'
                                    )}
                                </td>

                                <td className="px-4  py-2 ">
                                    <div className='flex gap-4'>
                                        <button
                                            onClick={() => handleDuplicate(course.id)} // Pass course id to duplicate
                                            className=" hover:underline"
                                        >
                                            <ContentCopyIcon style={{ fontSize: '14px' }} />
                                        </button>
                                        <button
                                            onClick={() => openModal(course.id)}// Call delete function with course ID
                                            className="hover:underline text-red-600"
                                        >
                                            <DeleteIcon style={{ fontSize: '14px' }} />
                                        </button>

                                    </div>

                                </td>




                            </tr>
                        ))}
                    </tbody>
                </table>

                {loading && <p>Loading...</p>}
            </div>



            <div className="flex gap-2 py-6  justify-center">

                <button
                    className=" border border-gray-600  text-gray-700  rounded-sm pt-sans-bold"
                    onClick={handlePrevious}
                >
                    <KeyboardDoubleArrowLeftIcon style={{ fontSize: '20px' }} />
                </button>
                <button
                    className=" border border-gray-600  text-gray-700  rounded-sm pt-sans-bold"
                    onClick={handlePrevious}
                >
                    <KeyboardArrowLeftIcon style={{ fontSize: '20px' }} />
                </button>

                <div className=" text-[14px] bg-black px-4 py-0.5 text-white pt-sans-bold">{currentPage}</div>
                {nextPage && (
                    <div>
                        <button
                            className="border border-gray-600 text-gray-700 rounded-sm pt-sans-bold"
                            onClick={handleNext}
                        >
                            <KeyboardArrowRightIcon style={{ fontSize: '20px' }} />
                        </button>
                    </div>
                )}
                {nextPage && (
                    <div>
                        <button
                            className="border border-gray-600 text-gray-700 rounded-sm pt-sans-bold"
                            onClick={handleNext}
                        >
                            <KeyboardDoubleArrowRightIcon style={{ fontSize: '20px' }} />
                        </button>
                    </div>
                )}


            </div>
            <AnimatePresence>
                {isDescriptionModalOpen && (
                    <DescriptionModal
                        isOpen={isDescriptionModalOpen}
                        onClose={handleCloseDescriptionModal}
                        onSave={handleSaveDescription}
                    />
                )}
            </AnimatePresence>
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
                    <div className="bg-white p-8 rounded-md shadow-md border border-black border-2 m-1">
                        <div className='flex'>
                            <div><DeleteForeverIcon style={{ color: 'red' }} /></div>
                            <div className="inter-light pb-6 mt-1 text-[15px] ">Are you sure you want to delete this Course ?</div> </div>
                        <div className="flex justify-end gap-4">
                            <button onClick={confirmDeactivation} className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light">Delete</button>
                            <button onClick={closeModal} className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light">Cancel</button>
                        </div>
                    </div>
                </div>
            )
            }

        </div >

    );
};

export default UniversityCourse;
