import React, { useState } from 'react';
import axiosInstance from '../axiosInstance/axiosInstance';

const AgencyDescription = () => {
    const [formData, setFormData] = useState({
        description: '',
        logo: null,
        logoName: '', // To store the selected logo name
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            logo: file,
            logoName: file ? file.name : '', // Store the selected file name
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const submitData = new FormData();
        submitData.append('description', formData.description);
        if (formData.logo) {
            submitData.append('logo', formData.logo);
        }

        try {
            const response = await axiosInstance.post('abc', submitData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                console.log('Agency details updated successfully');
            } else {
                console.error('Failed to update agency details:', response.data);
            }
        } catch (error) {
            console.error('Error updating agency details:', error);
        }
    };

    return (
        <div className='w-1/2'>
            <div className='bg-black flex items-center justify-center'>
                <span className="text-2xl font-bold px-6 py-6 text-white">
                    Agency Details
                </span>
            </div>
            <div className='bg-white shadow-lg px-10 py-10 rounded-md'>
                <form onSubmit={handleSubmit}>
                    <div className='flex flex-col gap-2 mb-4'>
                        <div className='inter-light text-[12px]' htmlFor="description">Description:</div>
                        <div>
                            <textarea
                                id="description"
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                placeholder="Enter agency description"
                                required
                                rows={4}
                                className="w-full p-2 inter-light resize-none text-[12px] border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div htmlFor="logo" className="block inter-light text-[12px]">
                            Agency Logo:
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <label
                                htmlFor="logo"
                                className="flex flex-col items-center justify-center w-full h-32 border-2 border-dashed border-gray-500 rounded-lg cursor-pointer hover:border-black"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-10 h-10 text-gray-400"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm10 7a1 1 0 00-1-1H7a1 1 0 100 2h6a1 1 0 001-1z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <span className="mt-2 inter-light text-[12px] text-gray-500">
                                    {formData.logoName ? formData.logoName : 'Click to upload logo'}
                                </span>
                                <input
                                    id="logo"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleLogoChange}
                                    className="hidden" // Hides the default file input
                                />
                            </label>
                        </div>
                    </div>
                    <div className='flex justify-end pt-8'>
                        <button
                            onClick={handleSubmit}
                            className="bg-black text-white font-bold py-1 px-6 text-[13px] rounded-sm inter-light"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AgencyDescription;

