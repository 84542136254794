import React, { useState, useEffect } from 'react'
import SideBar from '../SideBar/SideBar'
import UniversityHeader from './UniversityHeader'
import UniversiySearchBar from './UniversiySearchBar'
import UniversityCenter from './UniversityCenter'
import axiosInstance from '../axiosInstance/axiosInstance'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
const UniversityCenterDashboard = () => {
    const [isAddCountryVisible, setIsAddCountryVisible] = useState(false);
    const [isUpdateCountryVisible, setIsUpdateCountryVisible] = useState(false);
    const [isAddUniversityVisible, setIsAddUniversityVisible] = useState(false);
    const [isUpdateUniversityVisible, setIsUpdateUniversityVisible] = useState(false);
    const [universities, setUniversities] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [prevPage, setPrevPage] = useState(null);
    const [searchCountry, setSearchCountry] = useState('');
    const [searchUniversity, setSearchUniversity] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const toggleAddCountryModal = () => {
        setIsAddCountryVisible(prev => !prev);
    };
    const toggleAddUniversityModal = () => {
        setIsAddUniversityVisible(prev => !prev);
    };
    const fetchUniversities = (url = 'home/api/v1/universities/?limit=4', country = '',  search_university = '') => {
        axiosInstance.get(url, {
            params: {
                country: country,
                search_university:search_university,
            },
        })
            .then(response => {
                setUniversities(response.data.results);
                setNextPage(response.data.next);
                setPrevPage(response.data.previous);
            })
            .catch(error => {
                console.error("There was an error fetching the university data!", error);
            });
    };


    useEffect(() => {
        fetchUniversities('home/api/v1/universities/?limit=4', searchCountry, searchUniversity);
    }, [searchCountry, searchUniversity]);
    const handleNext = () => {
        if (nextPage) {
            fetchUniversities(nextPage);
            setCurrentPage((prev) => prev + 1);
        }
    };

    // Handle "Previous" button click
    const handlePrevious = () => {
        if (prevPage) {
            fetchUniversities(prevPage);
            setCurrentPage((prev) => prev - 1);
        }
    };





    return (
        <div>
            <div className='flex bg-gray-50 '>
                <div className='pt-10 bg-white h-screen sticky   top-0 '>
                    <SideBar />
                </div>
                <div className='flex flex-col px-4     w-full '>
                    <div className=' py-6 '>
                        <UniversityHeader />

                    </div>

                    <div className='shadow-md bg-white pb-4 flex flex-col overflow-x-auto gap-10 pt-10 px-4 h-full'>
                        <UniversiySearchBar toggleAddCountryModal={toggleAddCountryModal} toggleAddUniversityModal={toggleAddUniversityModal} onSearchCountry={setSearchCountry}
                            onSearchUniversity={setSearchUniversity} searchUniversity={searchUniversity} searchCountry={searchCountry} />
                        <UniversityCenter isAddCountryVisible={isAddCountryVisible} setIsAddCountryVisible={setIsAddCountryVisible} setIsAddUniversityVisible={setIsAddUniversityVisible} isAddUniversityVisible={isAddUniversityVisible} universities={universities} setUniversities={setUniversities} fetchUniversities={fetchUniversities} prevPage={prevPage} nextPage={nextPage} isUpdateCountryVisible={isUpdateCountryVisible} setIsUpdateCountryVisible={setIsUpdateCountryVisible} isUpdateUniversityVisible={isUpdateUniversityVisible} setIsUpdateUniversityVisible={setIsUpdateUniversityVisible} />

                    </div>
                    <div className="flex gap-2 py-6 items-center  justify-center ">

                        <button
                            className=" border border-gray-600  text-gray-700  rounded-sm pt-sans-bold"
                            onClick={handlePrevious}
                        >
                            <KeyboardDoubleArrowLeftIcon style={{ fontSize: '20px' }} />
                        </button>
                        <button
                            className=" border border-gray-600  text-gray-700  rounded-sm pt-sans-bold"
                            onClick={handlePrevious}
                        >
                            <KeyboardArrowLeftIcon style={{ fontSize: '20px' }} />
                        </button>

                        <div className=" text-[14px] bg-black px-4 py-0.5 text-white pt-sans-bold">{currentPage}</div>
                        {nextPage && (
                            <div>
                                <button
                                    className="border border-gray-600 text-gray-700 rounded-sm pt-sans-bold"
                                    onClick={handleNext}
                                >
                                   < KeyboardArrowRightIcon style={{ fontSize: '20px' }}/>
                                   
                                </button>
                            </div>
                        )}
                        {nextPage && (
                            <div>
                                <button
                                    className="border border-gray-600 text-gray-700 rounded-sm pt-sans-bold"
                                    onClick={handleNext}
                                >
                                    <KeyboardDoubleArrowRightIcon style={{ fontSize: '20px' }} />
                                </button>
                            </div>
                        )}


                    </div>


                </div>


            </div>





        </div>
    )
}

export default UniversityCenterDashboard