


import React, { useState, useEffect, useRef } from 'react';

import axiosInstance from '../axiosInstance/axiosInstance';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import UpdatedClientsForm from '../UpdatedClientsForm/UpdatedClientsForm';

import { AnimatePresence } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import UpdateStudentForm from '../UpdatedClientsForm/UpdateStudentForm';

const ListingTable = ({ menus, clients, fetchClients, setClients, handleAgencySelection, handleStageSelection, selectedStage, selectedAgency, fetchAdminOptions }) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedItemData1, setSelectedItemData1] = useState(null);
    const [selectedItemData2, setSelectedItemData2] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [isAssigneeOpen, setIsAssigneeOpen] = useState(false)
    const [isStageOpen, setIsStageOpen] = useState(false)
    const [isCategoryOpen, setIsCategoryOpen] = useState(false)
    const [isPriorityOpen, setIsPriorityOpen] = useState(false)
    const [webSocket, setWebSocket] = useState(null);
    const [agencyId, setAgencyId] = useState(null);
    const [isAgencyOpen, setIsAgencyOpen] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const reconnectIntervalRef = useRef(1000);
    const maxReconnectInterval = 30000;
    const reconnectAttemptsRef = useRef(0);
    const [showStageIcon, setShowStageIcon] = useState(false);
    const [showClearIcon, setShowClearIcon] = useState(false);
    const handleClearSelection = () => {
        handleAgencySelection(null);
        setShowClearIcon(false);
    };

    const handleClearStageSelection = () => {
        handleStageSelection(null);
        setShowStageIcon(false);
    };

    useEffect(() => {

        if (selectedAgency) {
            setShowClearIcon(true);
        } else {
            setShowClearIcon(false);
        }
    }, [selectedAgency]);
    useEffect(() => {

        if (selectedStage) {
            setShowStageIcon(true);
        } else {
            setShowStageIcon(false);
        }
    }, [selectedStage]);




    const assigneeDropdownRef = useRef(null);
    const categoryDropdownRef = useRef(null);
    const stageDropdownRef = useRef(null);
    const priorityDropdownRef = useRef(null);
    const agencyDropdownRef = useRef(null);
    const handleClickOutside = (event) => {
        if (
            assigneeDropdownRef.current &&
            !assigneeDropdownRef.current.contains(event.target)
        ) {
            setIsAssigneeOpen(false);
        }
        if (
            categoryDropdownRef.current &&
            !categoryDropdownRef.current.contains(event.target)
        ) {
            setIsCategoryOpen(false);
        }
        if (
            stageDropdownRef.current &&
            !stageDropdownRef.current.contains(event.target)
        ) {
            setIsStageOpen(false);
        }
        if (
            priorityDropdownRef.current &&
            !priorityDropdownRef.current.contains(event.target)
        ) {
            setIsPriorityOpen(false);
        }
        if (
            agencyDropdownRef.current &&
            !agencyDropdownRef.current.contains(event.target)
        ) {
            setIsAgencyOpen(false);
        }
    };


    useEffect(() => {
        // Add event listener when component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener when component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);





    const toggleListAgency = (itemId) => {

        setIsAgencyOpen(itemId === isAgencyOpen ? null : itemId);
        setIsPriorityOpen(false);
        setIsStageOpen(false);
        setIsCategoryOpen(false);
        setIsAssigneeOpen(false);

    };
    const handleAgencyChange = async (agencyID, client) => {
        try {
            console.log("client", client);

            await axiosInstance.patch(`kanban/api/v1/client/${client.id}/`, { 'agency': agencyID });

            const updatedAgency = { ...client, agency: agencyID };
            localStorage.setItem('updated_Agency', JSON.stringify(updatedAgency));

            // Emit custom event
            const event = new Event('agencyupdated');
            window.dispatchEvent(event);



            setIsAgencyOpen(false);

        } catch (error) {
            console.error('Error updating category:', error);
        }

    };




    const toggleListAssignee = (itemId) => {

        setIsAssigneeOpen(itemId === isAssigneeOpen ? null : itemId);
        setIsPriorityOpen(false);
        setIsStageOpen(false);
        setIsCategoryOpen(false);
        setIsAgencyOpen(false);

    };
    const toggleListPriority = async (item) => {
        console.log(item);

        try {
            const prioritiesResponse = await axiosInstance.get(`kanban/api/v1/priority_dropdown/?limit=&search=&stage=${item.stage.id}`);

            setOptions(prevOptions => ({
                ...prevOptions,
                priorities: prioritiesResponse.data
            }));
        } catch (error) {
            console.error('Error fetching priorities:', error);

        }

        setIsPriorityOpen(item.id === isPriorityOpen ? null : item.id);
        setIsAgencyOpen(false);
        setIsStageOpen(false);
        setIsCategoryOpen(false);
        setIsAssigneeOpen(false);
    };
    const toggleListStage = (itemId) => {
        setIsStageOpen(itemId === isStageOpen ? null : itemId);
        setIsAssigneeOpen(false);
        setIsPriorityOpen(false);
        setIsCategoryOpen(false);
    };
    const toggleListCategory = (itemId) => {
        setIsCategoryOpen(itemId === isCategoryOpen ? null : itemId);
        setIsAssigneeOpen(false);
        setIsPriorityOpen(false);
        setIsStageOpen(false);

    }

    useEffect(() => {
        fetchData();

    }, []);

    useEffect(() => {
        const userInfoString = localStorage.getItem('user_info');
        const userInfo = JSON.parse(userInfoString);
        if (userInfo) {
            const userAgencyId = userInfo.agency_id;
            setAgencyId(userAgencyId);
        }
    }, []);

    useEffect(() => {
        if (agencyId) {
            connectWebSocket();

            return () => {
                if (webSocket) {
                    webSocket.close();
                }
            };
        }
    }, [agencyId]);

    useEffect(() => {
        const selectedAgency = localStorage.getItem('selectedAgency');
        const selectedStage = localStorage.getItem('selectedStage');

        if (webSocket) {
            webSocket.onmessage = (event) => {
                const message = JSON.parse(event.data);


                if (message.event && message.event.refresh) {
                    if (selectedAgency || selectedStage) {

                        fetchClients(true);
                    } else {
                        console.log('Fetching clients without reset');
                        fetchClients();
                    }
                }
            };


            webSocket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            webSocket.onclose = (event) => {
                console.log('WebSocket connection closed:', event);
                handleReconnect();
            };
        }

        return () => {
            if (webSocket) {
                webSocket.onmessage = null;
                webSocket.onerror = null;
                webSocket.onclose = null;
            }
        };
    }, [webSocket]);

    const connectWebSocket = () => {
        const clientRoom = `client_${agencyId}`;
        const newWebSocket = new WebSocket(`wss://norvel.site/ws/client-refresh/${clientRoom}/`);
        setWebSocket(newWebSocket);
    };

    const handleReconnect = () => {
        reconnectAttemptsRef.current++;
        if (reconnectIntervalRef.current < maxReconnectInterval) {
            reconnectIntervalRef.current *= 2;
        }
        setTimeout(() => {
            console.log(`Reconnecting... Attempt ${reconnectAttemptsRef.current}`);
            connectWebSocket();
        }, reconnectIntervalRef.current);
    };


    const fetchData = async () => {
        try {
            const response = await axiosInstance.get('kanban/api/v1/clients/?search=&agency=&client=&limit=&offset=');
            setData(response.data);


            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error);
            setLoading(false);
        }
    };

    const handleCategoryChange = async (categoryID, client) => {
        try {
            console.log("client", client);

            await axiosInstance.patch(`kanban/api/v1/client/${client.id}/`, { 'category': categoryID });

            setIsCategoryOpen(false)


        } catch (error) {
            console.error('Error updating category:', error);
        }
    };
    const handleStageChange = async (stageID, client) => {
        try {


            await axiosInstance.patch(`kanban/api/v1/client/${client.id}/`, { 'stage': stageID });
            const updatedClient = { ...client, stage: stageID };
            localStorage.setItem('updated_client_stage', JSON.stringify(updatedClient));

            // Emit custom event
            const event = new Event('clientStageUpdated');
            window.dispatchEvent(event);



            setIsStageOpen(false);



        } catch (error) {
            console.error('Error updating category:', error);
        }
    };
    const handlePriorityChange = async (priorityID, client) => {
        try {
            console.log("client", client);

            await axiosInstance.patch(`kanban/api/v1/client/${client.id}/`, { 'priority': priorityID });

            setIsPriorityOpen(false)


        } catch (error) {
            console.error('Error updating category:', error);
        }
    };
    const handleAssigneeChange = async (assigneeID, client) => {
        try {
            console.log("client", client);

            await axiosInstance.patch(`kanban/api/v1/client/${client.id}/`, { 'assignee': assigneeID });

            setIsAssigneeOpen(false);


        } catch (error) {
            console.error('Error updating category:', error);
        }

    };


    const [options, setOptions] = useState({
        categories: [],
        stages: [],
        priorities: [],
        agencies: [],

    });

    const fetchOptions = async () => {

        try {
            // Fetch categories from backend API
            const categoriesResponse = await axiosInstance.get('kanban/api/v1/category_dropdown/?limit=&search=');
            setOptions(prevOptions => ({
                ...prevOptions,
                categories: categoriesResponse.data
            }));


            // Fetch stages from backend API
            const stagesResponse = await axiosInstance.get('kanban/api/v1/stage_dropdown/?limit=&search=');
            setOptions(prevOptions => ({
                ...prevOptions,
                stages: stagesResponse.data

            }));



            const assigneesResponse = await axiosInstance.get('kanban/api/v1/assign_staff');
            setOptions(prevOptions => ({
                ...prevOptions,
                assignees: assigneesResponse.data



            }));

            const agenciesResponse = await axiosInstance.get('kanban/api/v1/agency_dropdown/?limit=&search=');
            setOptions(prevOptions => ({
                ...prevOptions,
                agencies: agenciesResponse.data


            }));

            // Fetch priorities from backend API
            const prioritiesResponse = await axiosInstance.get(`kanban/api/v1/priority_dropdown/?limit=&search=&stage=`);
            setOptions(prevOptions => ({
                ...prevOptions,
                priorities: prioritiesResponse.data

            }));


        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    useEffect(() => {


        fetchOptions();

    }, []);




    const handleRowClick = async (id) => {
        try {
            const response1 = await axiosInstance.get(`kanban/api/v1/client/${id}`);
            const response2 = await axiosInstance.get(`kanban/api/v1/comments/?limit=&search=&client=${id}`);
            setSelectedItemData1(response1.data);


            setSelectedItemData2(response2.data);

            setModalVisible(true);
        } catch (error) {
            console.error('Error fetching item data:', error);
        }
    };
    useEffect(() => {
        setModalVisible(false);
    }, []);


    useEffect(() => {
        if (!selectedItemData1 || !selectedItemData2) {
            setModalVisible(false);
        }
    }, [selectedItemData1, selectedItemData2]);

    const handleCloseModal = () => {
        setSelectedItemData1(null);
        setSelectedItemData2(null);
        setModalVisible(false);
    };
    return (
        <>
            <div>
                <div className=" flex-grow smooth-scroll " >
                    {loading && <p>Loading...</p>}
                    {error && <p>Error: {error.message}</p>}
                    {data && data.length > 0 && (
                        <table className="w-full divide-y divide-gray-200  bg-gray-50   ">
                            <thead>
                                <tr>
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                        #
                                    </th>
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                        Created at
                                    </th>
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                        Full Name
                                    </th>
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                        Phone Number
                                    </th>
                                    {menus && menus.consultant && (
                                        <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                            Consultant
                                        </th>
                                    )}
                                    {menus && menus.assignee && (
                                        <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                            Assignee
                                        </th>
                                    )}
                                    {menus && menus.consultant && (
                                        <th className="inter-font flex justify-center items-center whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                            Category
                                            <span>
                                                <ArrowDropDownIcon style={{ fontSize: '20px', color: 'black' }} />
                                            </span>
                                        </th>
                                    )}
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                        Stage
                                    </th>
                                    <th className="inter-font flex justify-center items-center whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                        Priority
                                        <span>
                                            <ArrowDropDownIcon style={{ fontSize: '20px', color: 'black' }} />
                                        </span>
                                    </th>
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                        Last Updated
                                    </th>
                                    <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">
                                        Comments
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {clients.map((item, index) => (
                                    <tr key={index} className="border-b-2 border-gray-100 bg-white">
                                        <td className="inter-light  whitespace-nowrap  py-3 text-center text-[12px] text-black  tracking-wider ">
                                            {index + 1}
                                        </td>
                                        <td className="inter-light  whitespace-nowrap  py-3 text-center text-[12px] text-black  tracking-wider ">
                                            {item.created_at}
                                        </td>
                                        <td className="inter-light  whitespace-nowrap  py-3 text-center text-[12px] text-black  tracking-wider cursor-pointer   " onClick={() => handleRowClick(item.id)}>
                                            {item.name}
                                        </td>
                                        <td className="inter-light  whitespace-nowrap  py-3 text-center text-[12px] text-black  tracking-wider ">
                                            {item.mobile}
                                        </td>


                                        {menus && menus.consultant && (
                                            <td>

                                                <div className="relative flex justify-center" >

                                                    <button
                                                        onClick={() => toggleListAgency(item.id)}
                                                        className="rounded-full text-gray-700 rounded flex items-center justify-center  py-0.5"
                                                    >
                                                        <div className="inter-light text-black text-[12px]">
                                                            {item.agency ? item.agency.name : "Select Agency"}
                                                        </div>
                                                    </button>


                                                    {isAgencyOpen === item.id && (
                                                        <div className="absolute z-10 mt-6 bg-white rounded-lg shadow-lg overflow-y-auto" style={{ width: '90px', maxHeight: '200px' }} ref={agencyDropdownRef}>
                                                            <ul className="py-2 agency-dropdown-menu">
                                                                {loading ? (
                                                                    <li>Loading...</li>
                                                                ) : error ? (
                                                                    <li>Error: {error.message}</li>
                                                                ) : (
                                                                    options.agencies.map((agency) => (
                                                                        <li
                                                                            key={agency.id}
                                                                            className="px-2 py-2 text-[10px] hover:bg-gray-100 cursor-pointer"
                                                                            onClick={() => handleAgencyChange(agency.id, item)}
                                                                        >
                                                                            <div className='flex justify-start items-start gap-1'>

                                                                                <div className='flex justify-start items-start gap-1'>
                                                                                    <div style={{ backgroundColor: `#${agency.bg_color}`, width: '10px', height: '10px', borderRadius: '80px' }}></div>
                                                                                    <div className='inter-font'>{agency.name}</div></div>
                                                                            </div>
                                                                        </li>
                                                                    ))
                                                                )}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>
                                            </td>)}

                                        {menus && menus.assignee && (
                                            <td>
                                                <div className="relative  flex justify-center">
                                                    <button
                                                        onClick={() => toggleListAssignee(item.id)}
                                                        className=" rounded-full text-gray-700  rounded flex items-center justify-center  py-0.5 "
                                                    >
                                                        <div className="inter-font text-black text-[12px]">


                                                            {item.assignee ? item.assignee.name : "Select Assignee "}
                                                        </div>
                                                        {/* <ArrowDropDownIcon style={{ fontSize: '18px' }} /> */}

                                                    </button>
                                                    {isAssigneeOpen === item.id && (
                                                        <div className=" inter-font absolute z-10 mt-6  bg-white rounded-lg shadow-lg overflow-y-auto " style={{ width: '90px', maxHeight: '200px' }} ref={assigneeDropdownRef} >
                                                            <ul className="py-2">
                                                                {loading ? (
                                                                    <li>Loading...</li>
                                                                ) : error ? (
                                                                    <li>Error: {error.message}</li>
                                                                ) : (
                                                                    options.assignees.map((assignee) => (
                                                                        <li
                                                                            key={assignee.id}
                                                                            className="px-2 py-2 text-[10px]  hover:bg-gray-100 cursor-pointer"
                                                                            onClick={() => {
                                                                                handleAssigneeChange(assignee.id, item);
                                                                            }}
                                                                        >
                                                                            <div className='flex justify-start items-center gap-1'> <div style={{ backgroundColor: 'gray', width: '10px', height: '10px', borderRadius: '80px' }}></div> <div className='inter-font'>{assignee.name}</div> </div>

                                                                        </li>
                                                                    ))
                                                                )}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>
                                            </td>
                                        )}

                                        {menus && menus.consultant && (
                                            <td>
                                                <div >
                                                    <div className="relative flex justify-center" >
                                                        <button
                                                            onClick={() => toggleListCategory(item.id)}
                                                            style={{
                                                                backgroundColor: `#${item.category?.
                                                                    bg_color
                                                                    }`, borderRadius: '60px', fontSize: '10px', display: 'flex', alignItems: "center", justifyContent: "center", width: '90px', paddingBottom: 'O.5px', paddingTop: '0.5px'
                                                            }}
                                                        >
                                                            <div className='inter-light ' style={{ Color: `#${item.category?.color_code}`, fontSize: '12px' }}>
                                                                {item.category ? item.category.name : "Select category"}

                                                            </div>



                                                        </button>
                                                        {isCategoryOpen === item.id && (
                                                            <div className="absolute z-10 mt-6 w-32 bg-white rounded-lg shadow-lg overflow-y-auto " style={{ width: '90px', maxHeight: '200px' }} ref={categoryDropdownRef}>
                                                                <ul className="py-2 ">
                                                                    {loading ? (
                                                                        <li>Loading...</li>
                                                                    ) : error ? (
                                                                        <li>Error: {error.message}</li>
                                                                    ) : (
                                                                        options.categories
                                                                            .map((category) => (
                                                                                <li
                                                                                    key={category.id}
                                                                                    className="px-2 py-2 text-[10px] hover:bg-gray-100 cursor-pointer"
                                                                                    onClick={() => {
                                                                                        handleCategoryChange(category.id, item);

                                                                                    }}
                                                                                >
                                                                                    <div className='flex justify-start items-center gap-1'> <div style={{ backgroundColor: `#${category.bg_color}`, width: '10px', height: '10px', borderRadius: '80px' }}></div><div className='inter-font'>{category.name}</div></div>

                                                                                </li>
                                                                            ))
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                            </td>)}




                                        <td>
                                            <div className="relative flex justify-center" >
                                                <button
                                                    onClick={() => toggleListStage(item.id)}
                                                    style={{
                                                        backgroundColor: `#${item.stage?.
                                                            bg_color
                                                            }`, borderRadius: '60px', fontSize: '10px', display: 'flex', alignItems: "center", justifyContent: "center", width: '90px', paddingBottom: 'O.5px', paddingTop: '0.5px'
                                                    }}
                                                >
                                                    <div className='inter-light whitespace-nowrap ' style={{ color: `#${item.stage?.color_code}`, fontSize: '12px', }}>
                                                        {item.stage ? item.stage.name : "Select Stage"}
                                                    </div>
                                                    {/* <div>  <ArrowDropDownIcon style={{ fontSize: '18px' }} /></div> */}

                                                </button>
                                                {isStageOpen === item.id && (
                                                    <div className="absolute z-10 mt-6 w-32 bg-white rounded-lg shadow-lg overflow-y-auto whitespace-nowrap" style={{ width: '90px', maxHeight: '200px' }} ref={stageDropdownRef}>
                                                        <ul className="py-2">
                                                            {loading ? (
                                                                <li>Loading...</li>
                                                            ) : error ? (
                                                                <li>Error: {error.message}</li>
                                                            ) : (
                                                                options.stages

                                                                    .map((stage) => (
                                                                        <li
                                                                            key={stage.id}
                                                                            className="px-2 py-2 text-[10px] hover:bg-gray-100 cursor-pointer"
                                                                            onClick={() => {
                                                                                handleStageChange(stage.id, item); // Pass item as well
                                                                                // Close the dropdown after selection
                                                                            }}
                                                                        >
                                                                            <div className='flex justify-start items-center gap-1 overflow-x-auto hidden-scrollbar'> <div style={{ backgroundColor: `#${stage.bg_color}`, width: '10px', height: '10px', borderRadius: '80px' }}></div><div className='inter-font'>{stage.name}</div></div>

                                                                        </li>
                                                                    ))
                                                            )}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="relative flex justify-center" >
                                                <button
                                                    onClick={() => toggleListPriority(item)}
                                                    style={{ borderRadius: '60px', padding: '2px 10px', fontSize: '10px', display: 'flex', alignItems: "center", justifyContent: "center" }}
                                                >
                                                    <span className=" text-gray-400 text-[10px] inter-font">
                                                        {item.priority ? (
                                                            <img src={item.priority.icon} style={{ width: '20px', marginRight: '5px' }} />
                                                        ) : (
                                                            "Select Priority"
                                                        )}
                                                    </span>

                                                </button>
                                                {isPriorityOpen === item.id && (
                                                    <div className="absolute z-10  mt-6 w-32 bg-white rounded-lg shadow-lg " style={{ width: '100px', scrollbarWidth: 'thin' }} ref={priorityDropdownRef} >
                                                        <ul className="py-2 max-h-40 overflow-y-auto ">
                                                            {loading ? (
                                                                <li>Loading...</li>
                                                            ) : error ? (
                                                                <li>Error: {error.message}</li>
                                                            ) : (
                                                                options.priorities.map((priority) => (
                                                                    <li
                                                                        key={priority.id}
                                                                        className="px-2 inter-font py-2 hover:bg-gray-100 cursor-pointer flex items-center text-[10px] justify-center  "
                                                                        onClick={() => {
                                                                            handlePriorityChange(priority.id, item);
                                                                        }}
                                                                    ><div> <img src={priority.icon} style={{ width: '20px', height: '20px', marginRight: '5px' }} /></div>
                                                                        <div>{priority.name}</div>


                                                                    </li>
                                                                ))
                                                            )}
                                                        </ul>

                                                    </div>
                                                )}
                                            </div>
                                        </td>


                                        <td className="inter-light  whitespace-nowrap px-4 py-3 text-center text-[12px] text-black  tracking-wider">{item.modified_at}</td>
                                        <td className="inter-light whitespace-nowrap px-4 py-3 text-center text-[12px] text-black tracking-wider overflow-x-auto hidden-scrollbar" style={{ maxWidth: '50px', overflowX: 'auto' }}>
                                            {item.
                                                description}
                                        </td>
                                    </tr>

                                ))}
                            </tbody>
                        </table>
                    )
                    }
                    <AnimatePresence>

                        {
                            modalVisible && selectedItemData1 && selectedItemData2 && (

                                <UpdateStudentForm setClients={setClients} menus={menus} selectedItemData1={selectedItemData1} setSelectedItemData1={setSelectedItemData1} selectedItemData2={selectedItemData2} setSelectedItemData2={setSelectedItemData2} onClose={handleCloseModal} setModalVisible={setModalVisible} />
                            )
                        }


                    </AnimatePresence>
                </div >
            </div >
        </>
    );
};

export default ListingTable;





