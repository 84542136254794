
import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../axiosInstance/axiosInstance';
import CloseIcon from '@mui/icons-material/Close';

import { motion } from 'framer-motion';
import StageDropDown from '../DropDowns/StageDropDown';
import CategoryDropDown from '../DropDowns/CategoryDropDown';
import PriorityDropDown from '../DropDowns/PriorityDropDown';
import AgencyDropDown from '../DropDowns/AgencyDroDown';
import AssigneeDropDown from '../DropDowns/AssigneeDropDown';

import SourceDropDown from '../DropDowns/SourceDropDown';
import { Tabs, Tab, Box } from '@mui/material';



const AddStudent = ({ onClose, setIsAddClientVisible, menus, fetchClients }) => {

    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        agency: '',
        category: '',
        stage: '',
        priority: '',
        course: '',
        degree: '',
        assignee: '',
        preferred_country: '',
        percentage: '',
        passed_year: '',
        description: '',
        source: '',
    });
    const [comment, setComment] = useState('');
    const dropdownRef = useRef(null);

    const [clientId, setClientId] = useState(null);
    const [errors, setErrors] = useState({});
    const [isVisible, setIsVisible] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isAssigneeOpen, setIsAssigneeOpen] = useState(false)
    const [isStageOpen, setIsStageOpen] = useState(false)
    const [isCategoryOpen, setIsCategoryOpen] = useState(false)
    const [isPriorityOpen, setIsPriorityOpen] = useState(false)
    const [isCountryOpen, setIsCountryOpen] = useState(false)
    const [degreeTabs, setDegreeTabs] = useState([]);
    const [selectedDegreeIndex, setSelectedDegreeIndex] = useState(0);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsCountryOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);



    const toggleListStage = (itemId) => {
        setIsStageOpen(itemId === isStageOpen ? null : itemId);
        setIsAssigneeOpen(false);
        setIsPriorityOpen(false);
        setIsCategoryOpen(false);
    };

    const [options, setOptions] = useState({
        categories: [],
        stages: [],
        priorities: [],
        agencies: [],
        degrees: [],
        staffs: [],
        countries: [],
        sources: [],
    });

    const validateForm = () => {
        const newErrors = {};

        // Name validation
        if (!formData.name || !formData.name.trim()) {
            newErrors.name = 'Name is required';
        }

        // Phone number validation
        if (!formData.mobile || !formData.mobile.trim()) {
            newErrors.mobile = 'Phone Number is required';
        } else if (formData.mobile.trim().length !== 10) {
            newErrors.mobile = 'Phone Number must be 10 digits';
        }

        // // Agency validation
        // if (!formData.agency) {
        //     newErrors.agency = 'Agency is required';
        // }



        if (!formData.course) {
            newErrors.course = 'Course is required';
        }



        // // Assignee validation
        // if (!formData.assignee) {
        //     newErrors.assignee = 'Assignee is required';
        // }

        // Preferred country validation
        if (!formData.preferred_country) {
            newErrors.preferred_country = 'country is required';
        }

        // Percentage validation
        if (!formData.percentage || isNaN(formData.percentage)) {
            newErrors.percentage = 'Valid percentage is required';
        }

        // Passed year validation
        if (!formData.passed_year || isNaN(formData.passed_year)) {
            newErrors.passed_year = 'Valid passed year is required';
        }

       

        // // Source validation
        // if (!formData.source) {
        //     newErrors.source = 'Source is required';
        // }

        setErrors(newErrors);

        // Return true if no errors
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const response = await axiosInstance.post('kanban/api/v1/clients/', {
                    ...formData,
                    preferred_country: formData.preferred_country // Map to primary key values if necessary
                });

                if (response.status === 201) {
                    setIsAddClientVisible(false);
                    console.log('Response from API:', response.data);
                    const client = response.data.id;
                    setClientId(client);

                    // Reset form data
                    setFormData({
                        name: '',
                        mobile: '',
                        agency: '',
                        category: '',
                        stage: '',
                        priority: '',
                        course: '',
                        degree: '',
                        assignee: '',
                        preferred_country: '',
                        percentage: '',
                        passed_year: '',
                        description: '',
                        source: '',
                    });

                    // Handle comment if available
                    if (comment.trim()) {
                        await handleCommentSubmit(client, comment);
                    }

                    // Fetch updated list of clients after submission
                    await fetchClients();  // Ensure this function fetches the updated list of clients
                   
                    // Close the modal or form after submission
                    onClose();
                }
            } catch (error) {
                console.error('Error submitting the API request:', error);
            }
        }
    };

    const handleCommentSubmit = async (client, comment) => {
        try {
            const response = await axiosInstance.post('kanban/api/v1/comments/', { comment, client });
            console.log('Response from second API:', response.data);
        } catch (error) {
            console.error('Error submitting second API request:', error);
            if (error.response) {
                console.error('Error status:', error.response.status);
                console.error('Error data:', error.response.data);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    const handleClose = () => {
        onClose();
        setIsVisible(false);
    };

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const categoriesResponse = await axiosInstance.get('kanban/api/v1/category_dropdown/?limit=&search=');
                const stagesResponse = await axiosInstance.get('kanban/api/v1/stage_dropdown/?limit=&search=');

                const prioritiesResponse = await axiosInstance.get('kanban/api/v1/priority_dropdown/?limit=&search=&stage=');
                const agenciesResponse = await axiosInstance.get('kanban/api/v1/agency_dropdown/?limit=&search=');
                const degreesResponse = await axiosInstance.get('kanban/api/v1/degree_dropdown/?search=');
                const staffsResponse = await axiosInstance.get('kanban/api/v1/assign_staff');
                const countriesResponse = await axiosInstance.get('kanban/api/v1/country_dropdown');
                const sourcesResponse = await axiosInstance.get('kanban/api/v1/source_dropdown/');
                console.log(sourcesResponse.data, "hello source");


                setOptions({
                    categories: categoriesResponse.data,
                    stages: stagesResponse.data,
                    priorities: prioritiesResponse.data,
                    agencies: agenciesResponse.data,
                    degrees: degreesResponse.data,
                    staffs: staffsResponse.data,
                    countries: countriesResponse.data,
                    sources: sourcesResponse.data,

                });
                setDegreeTabs(degreesResponse.data);
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchOptions();
    }, []);


    const handleChange = (e) => {
        console.log('Event object:', e); // Debugging statement to check event object
        const { name, value } = e.target; // Destructure 'name' and 'value' from 'e.target'

        // Update formData state
        setFormData({
            ...formData,
            [name]: value,
        });


        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const handleCountryToggle = () => {
        setIsCountryOpen(!isCountryOpen);
    };


    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handleCommentButtonClick = async () => {
        if (clientId && comment.trim()) {
            await handleCommentSubmit(clientId, comment);
            setComment('');
        } else {
            console.error('Client ID or comment is missing');
        }
    };
    const toggleListPriority = async (selectedStageId) => {
        try {
            const prioritiesResponse = await axiosInstance.get(`kanban/api/v1/priority_dropdown/?limit=&search=&stage=${selectedStageId}`);
            setOptions(prevOptions => ({
                ...prevOptions,
                priorities: prioritiesResponse.data
            }));
        } catch (error) {
            console.error('Error fetching priorities:', error);

        }
    };
    const handleCountrySelect = (value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            preferred_country: value, // Set single value
        }));
        setErrors({
            ...errors,
            preferred_country: '',  // Reset the error for preferred_country
        });

        setIsCountryOpen(false);
    };

    const handleTagRemove = (e) => {
        e.stopPropagation();
        setFormData(prevFormData => ({
            ...prevFormData,
            preferred_country: '', // Reset to empty value
        }));
        setIsCountryOpen(false);
    };
    const handleDegreeTabChange = (event, newValue) => {
        setSelectedDegreeIndex(newValue);
        setFormData(prevFormData => ({
            ...prevFormData,
            degree: degreeTabs[newValue].id,
        }));
    };





    return (
        <div>
            <motion.div
                className=" fixed inset-0 bg-black bg-opacity-70"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={onClose}
            />
            <>
                <motion.div
                    className="fixed inset-0 flex justify-center items-center overflow-y-auto "

                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}

                >

                    <div className='absolute z-10 top-[-30px]  w-full'>
                        <div className="flex items-center justify-center py-10">
                            <div className='bg-white  w-[760px] relative  rounded-md max-h-full overflow-y-auto '>
                                <div className=' absolute right-2 top-4 flex justify-end gap-4 items-end px-6 py-2'>


                                    <div className="bg-gray-200 rounded-full w-6 h-6 flex justify-center items-center" onClick={onClose} ><CloseIcon style={{ fontSize: 'small' }} /></div>
                                </div>
                                <div className=" flex gap-10  px-10 py-6 shadow-lg">

                                    <div className="">
                                        <div className="flex justify-between ">
                                            <div className=" text-xl pb-4 inter-bold">Profile</div>
                                            <div>
                                                <button
                                                    id="upload_button"
                                                    className="px-4 py-1 inter-font text-white bg-black text-[13px] rounded  focus:outline-none focus:ring-2 focus:ring-blue-400"
                                                >
                                                    Upload Documents
                                                </button>
                                            </div>
                                        </div>
                                        <div className="flex gap-4">
                                            <div className="mb-4">
                                                <label
                                                    className="block text-gray-400 w-[200px] text-[10px] font-medium  tracking-widest mb-2"
                                                    htmlFor="name"
                                                >
                                                    NAME
                                                </label>
                                                <input
                                                    className=" text-[12px] inter-light  rounded-md border border-gray-300 rounded w-[210px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}

                                                />
                                                {errors.name && <p className="text-red-500 text-[10px] text-xs">{errors.name}</p>}
                                            </div>
                                            <div className="mb-4">
                                                <label
                                                    className="block text-gray-400 text-[10px] w-[210px] font-medium  tracking-widest mb-2"
                                                    htmlFor="phone"
                                                >
                                                    PHONE NUMBER
                                                </label>
                                                <input
                                                    className=" text-[12px] inter-light  rounded-md  border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    type="text"
                                                    id="mobile"
                                                    name="mobile"
                                                    value={formData.mobile}
                                                    onChange={handleChange}

                                                />
                                                {errors.mobile && <p className="text-red-500 text-[10px] text-xs ">{errors.mobile}</p>}
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                className="block text-gray-400 text-[10px]  font-medium tracking-widest "
                                                htmlFor="degree"
                                            >
                                                DEGREE
                                            </label>
                                            <Box sx={{ width: '100%' }}>
                                                <Tabs
                                                    value={selectedDegreeIndex}
                                                    onChange={handleDegreeTabChange}
                                                    aria-label="Degree Tabs"
                                                >
                                                    {degreeTabs.map((degree, index) => (
                                                        <Tab key={index} label={degree.name}
                                                            sx={{
                                                                fontSize: '10px',
                                                                color: 'gray',
                                                                fontWeight: '500',
                                                                '&.Mui-selected': {
                                                                    color: 'blue.600', // Change this to the desired color for the selected tab
                                                                },
                                                            }} style={{ fontSize: '10px' }} className='inter-light'
                                                        />
                                                    ))}
                                                </Tabs>
                                            </Box>
                                            <input
                                                className="text-[12px] hidden inter-light rounded-md border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="degree"
                                                name="degree"
                                                value={formData.degree}
                                                readOnly
                                            />

                                        </div>

                                        <div>
                                            <div className="mb-4">
                                                <label
                                                    className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                    htmlFor="phone"
                                                >
                                                    COURSE STUDIED
                                                </label>
                                                <input
                                                    className=" text-[12px] inter-light rounded-md  border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                                                    type="text"
                                                    id="course"
                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}

                                                />
                                                {errors.course && <p className="text-red-500 text-[10px] text-xs ">{errors.course}</p>}
                                            </div>
                                        </div>
                                        <div className="flex gap-4">
                                            <div className="mb-4">
                                                <label
                                                    className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                    htmlFor="name"
                                                >
                                                    PERCENTAGE
                                                </label>
                                                <input
                                                    className=" text-[12px] inter-light  rounded-md  border border-gray-300 rounded w-[210px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    type="text"
                                                    id="percentage"
                                                    name="percentage"

                                                    value={formData.percentage}
                                                    onChange={handleChange}

                                                />
                                                {errors.percentage && <p className="text-red-500 text-[10px] text-xs ">{errors.percentage}</p>}
                                            </div>
                                            <div className="mb-4">
                                                <label
                                                    className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                    htmlFor="phone"
                                                >
                                                    PASSED YEAR
                                                </label>
                                                <input
                                                    className=" text-[12px] inter-light  rounded-md  border border-gray-300 rounded w-[210px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    type="text"
                                                    id="passed_year"
                                                    name="passed_year"

                                                    value={formData.passed_year}
                                                    onChange={handleChange}

                                                />
                                                {errors.passed_year && <p className="text-red-500 text-[10px] text-xs ">{errors.passed_year}</p>}
                                            </div>
                                        </div>
                                        <div>
                                            <div className="mb-4" ref={dropdownRef}>
                                                <label
                                                    className="block text-gray-400 text-[10px] font-medium tracking-widest mb-2"

                                                >
                                                    PREFERRED COUNTRY
                                                </label>
                                                <div className={`flex flex-wrap gap-2 border border-gray-300 ${formData.preferred_country ? 'py-1' : 'py-4'}  rounded-md`} onClick={handleCountryToggle}>
                                                    {formData.preferred_country && (
                                                        <div className='px-2'>
                                                            <div
                                                                className="flex items-center justify-center bg-blue-100 px-2 border border-blue-300"
                                                            >

                                                                <CloseIcon style={{ fontSize: '12px', color: '317FF6' }} onClick={handleTagRemove} />

                                                                <div className='border-l px-1 border-1 border-blue-300 h-6'></div>
                                                                <div className='text-[12px] text-blue-400 inter-light'>
                                                                    {options.countries.find(city => city.id === formData.preferred_country)?.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                {isCountryOpen && (
                                                    <ul className="absolute z-10 bg-white border border-gray-200 rounded-md mt-1 max-h-32 overflow-auto">
                                                        {options.countries.map(option => (
                                                            <div
                                                                key={option.id}
                                                                className="option"
                                                                onClick={() => handleCountrySelect(option.id)}
                                                            >
                                                                <div className='flex-flex-col items-center justify-center gap-2 w-[430px]'>
                                                                    <div className='flex gap-2 items-center hover:bg-gray-100 px-2 cursor-pointer'>
                                                                        <div style={{ backgroundColor: `#${option.bg_color}`, cursor: 'pointer' }} className='w-2 h-2 rounded-full'></div>
                                                                        <div className='py-1 inter-light text-[11px]'>
                                                                            {option.name}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </ul>
                                                )}
                                                {errors.preferred_country && <p className="text-red-500 text-[10px] text-xs ">{errors.preferred_country}</p>}
                                            </div>

                                        </div>
                                        <div className="mb-4">
                                            <label
                                                className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                htmlFor="about_candidate"
                                            >
                                                About Candidate
                                            </label>
                                            <textarea
                                                className="shadow appearance-none resize-none text-[12px] inter-light  border  border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="about_candidate"
                                                placeholder="Write about the candidate"
                                                rows={4}
                                                defaultValue={""}
                                                value={formData.description}
                                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                            />
                                        </div>

                                    </div>
                                    <div className="w-1/3">
                                        <div className=" text-xl inter-bold">Details</div>
                                        <div className="flex flex-col py-2 ">

                                        </div>
                                        <div className="flex flex-col">
                                            <div className="mb-4">
                                                <label
                                                    className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                    htmlFor="name"
                                                >
                                                    STAGE
                                                </label>
                                                <StageDropDown
                                                    options={options.stages}
                                                    value={formData.stage}
                                                    onChange={(selectedOptionId) => setFormData({ ...formData, stage: selectedOptionId })}

                                                />
                                            </div>
                                            <div className="mb-4">
                                                <label
                                                    className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                    htmlFor="name"
                                                >
                                                    PRIORITY
                                                </label>
                                                <PriorityDropDown options={options.priorities} value={formData.priority} onChange={handleChange} toggleListPriority={toggleListPriority} selectedStageId={formData.stage} />
                                            </div>
                                            <div className="mb-4">
                                                <label
                                                    className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                    htmlFor="name"
                                                >
                                                    CATEGORY
                                                </label>
                                                <CategoryDropDown
                                                    options={options.categories}
                                                    value={formData.category}
                                                    onChange={(selectedOptionId) => setFormData({ ...formData, category: selectedOptionId })}

                                                />
                                            </div>

                                            {menus && menus.source && (




                                                <div className="mb-4">
                                                    <label
                                                        className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                        htmlFor="name"
                                                    >
                                                        SOURCE
                                                    </label>
                                                    <SourceDropDown
                                                        options={options.sources}
                                                        value={formData.source}
                                                        onChange={(selectedOptionId) => setFormData({ ...formData, source: selectedOptionId })}

                                                    />
                                                    {/* {errors.source && <p className="text-red-500 text-[10px] text-xs italic">{errors.source}</p>} */}
                                                </div>
                                            )}

                                            {menus && menus.assign_agency && (
                                                <div className="mb-4">
                                                    <label
                                                        className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                        htmlFor="name"
                                                    >
                                                        ASSIGNED TO
                                                    </label>
                                                    <AgencyDropDown
                                                        options={options.agencies}
                                                        value={formData.agency}
                                                        onChange={(selectedOptionId) => setFormData({ ...formData, agency: selectedOptionId })}

                                                    />
                                                    {/* {errors.agency && <p className="text-red-500 text-xs text-[10px] italic">{errors.agency}</p>} */}
                                                </div>
                                            )}
                                            <div className="mb-4">
                                                <label
                                                    className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                    htmlFor="name"
                                                >
                                                    HANDLED BY
                                                </label>
                                                <AssigneeDropDown
                                                    options={options.staffs}
                                                    value={formData.assignee}
                                                    onChange={(selectedOptionId) => setFormData({ ...formData, assignee: selectedOptionId })}

                                                />
                                                {/* {errors.assignee && <p className="text-red-500 text-[10px] text-xs italic">{errors.assignee}</p>} */}

                                            </div>
                                            <div className="flex flex-col gap-4">
                                                <div className="pt-16">
                                                    <button

                                                        onClick={handleSubmit}
                                                        className="px-4 py-2 inter-font  w-full text-black bg-green-500 text-sm rounded  focus:outline-none "
                                                    >
                                                        CREATE
                                                    </button>
                                                </div>
                                                <div>
                                                    <button
                                                        id="upload_button"
                                                        className="px-4 py-2  inter-font  w-full text-white bg-black text-sm  rounded  focus:outline-none focus:ring-2 focus:ring-blue-400"
                                                    >
                                                        FOLLOW UP
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>

                    </div>
                </motion.div>
            </>

        </div>
    )
}

export default AddStudent